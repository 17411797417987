import { moveToAnchorId } from './src/assets/scripts/helpers';

/*
    The Gatsby Link component by default will bring you to
    the scrolled position of the page you're navigating to
    the last time you visited that page. This fix makes it
    so every time you visit a page it shows the top of the
    page. Also "top of the page to ya - hey sha shay" 🍀
*/

export const shouldUpdateScroll = () => {
    if (typeof window !== 'undefined' && window.location.href.includes('#')) {
        moveToAnchorId(window.location.href);
    } else {
        window.scrollTo(0, 0);
    }

    return false;
}

export const onInitialClientRender = () => {
    if (typeof window !== 'undefined' && window.location.href.includes('#')) {
        moveToAnchorId(window.location.href)
    } else {
        window.scrollTo(0, 0);
    }
}