import Scroll from 'react-scroll';

export const moveToAnchorId = url => {
    // check for links with ids in them
    let id = url.split('#')[1];

    // if the id in the url of the button clicked
    // matches an id on the current page, scroll
    // to the corresponding element
    if (document.getElementById(id) !== null) {
        Scroll.scroller.scrollTo(id, {
            duration: 1500,
            smooth: true,
        });
    }
};

export const KebabCase = string => string
    .replace(/([a-z])([A-Z])/g, "$1-$2")
    .replace(/[\s_]+/g, '-');